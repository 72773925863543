<template>
  <item
    :config="config"
    :open="open"
    :searchHighlights="searchHighlights"
    :listItem="listItem"
    :narrow="narrow"
    v-on="$listeners"
    class="p-m-ordinance-item"
  >
    <div v-if="listItem" class="item__closed-stage">{{ listItem.stage }}</div>
  </item>
</template>

<script>
import Item from '../../components/Item/Item';
import { PMOrdinanceItemPrepare } from './util/PMOrdinanceItemPrepare';

export default {
  components: { Item },
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `${window.location.origin}${this.$route.fullPath}`,
        },
      ],
    };
  },
  props: {
    open: Boolean,
    searchHighlights: {
      query: String,
      keywords: Array,
    },
    listItem: Object,
    narrow: Boolean,
  },
  data() {
    return {
      config: {
        routeName: 'p-m-ordinances-item',
        routeList: 'p-m-ordinances',
        route404: 'p-m-ordinances',
        prepareData: PMOrdinanceItemPrepare,
        api: {
          body: 'pm_ordinance_body',
          highlights: 'pm_ordinances/load_highlights',
          monitored: 'pm_ordinances/load_provisions_with_favorite_flag',
          additional: 'pm_ordinance_provisions_and_versions',
          textVersions: {
            lastVersion: 'v1/pm_ordinance_last_version_button',
            version: 'v1/pm_ordinance_version',
          },
          descriptions: 'v1/pm_ordinance_other_descriptions',
        },
        bookmark: {
          type: 'p-m-ordinances',
          fetch: 'fetchPMOrdinances',
          toggle: 'togglePMOrdinances',
        },
        labels: {
          linksTitle: 'Projekt na stronie:',
          linksDoneTitle: 'Zarządzenie na stronie:',
        },
      },
    };
  },
};
</script>

<style lang="scss">
@import './PMOrdinancesItem';
</style>
