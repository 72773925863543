<template>
  <monitoring-items
    :activeModules="activeModules"
    :mapItems="mapPMOrdinancesItems"
    :api="{
      moduleId: 'pk',
      modulesWithName: 'custom_query_pm_ordinance_with_names',
      modules: 'module_pm_ordinances_header',
      favoriteHated: 'favorite_and_hated_pm_ordinances_header',
      favoriteHatedResponse: 'favorite_and_hateful_pm_ordinances_list',
      bookmarks: 'bookmarks/fetchPMOrdinances',
    }"
    #default="{items}"
  >
    <p-m-ordinances-list :items="items" />
  </monitoring-items>
</template>

<script>
import MonitoringItems from '../components/MonitoringItems';
import PMOrdinancesList from '../../PMOrdinances/PMOrdinancesList';
import { mapPMOrdinancesItems } from '../../PMOrdinances/mapPMOrdinancesItems';

export default {
  props: {
    activeModules: Array,
  },
  components: {
    MonitoringItems,
    PMOrdinancesList,
  },
  metaInfo() {
    return {
      title: 'Monitoring zarządzeń Prezesa Rady Ministrów',
    };
  },
  data() {
    return {
      mapPMOrdinancesItems,
    };
  },
};
</script>
