var render = function render(){var _vm=this,_c=_vm._self._c;return _c('monitoring-items',{attrs:{"activeModules":_vm.activeModules,"mapItems":_vm.mapPMOrdinancesItems,"api":{
    moduleId: 'pk',
    modulesWithName: 'custom_query_pm_ordinance_with_names',
    modules: 'module_pm_ordinances_header',
    favoriteHated: 'favorite_and_hated_pm_ordinances_header',
    favoriteHatedResponse: 'favorite_and_hateful_pm_ordinances_list',
    bookmarks: 'bookmarks/fetchPMOrdinances',
  }},scopedSlots:_vm._u([{key:"default",fn:function({items}){return [_c('p-m-ordinances-list',{attrs:{"items":items}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }